import * as React from 'react';

const Benefits: React.FC = () => {

	return (
		<section className={'px-5'}>
			<div className={'container mx-auto flex flex-col gap-8 py-12'}>
				<h2 className="py-4 text-center font-kiona text-4xl  font-normal text-secondary max-sm:text-3xl">
					BENEFITS
				</h2>
				<div
					className={
						'mb-10 grid grid-cols-3 items-center gap-8 gap-y-12 max-[400px]:grid-cols-1'
					}
				>
					<div className="flex flex-col items-center gap-5">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="80"
							height="80"
							viewBox="0 0 79 71"
							fill="none"
						>
							<path
								d="M2.00482 38.4599H17.8562L25.9744 22.7547L38.611 50.1389L50.4823 29.9782L56.6203 38.7807H76.669M74.61 31.0785C76.3531 27.5309 77 24.2744 77 21.3831C77 15.1464 72.4866 2.23386 57.8352 2.23386C43.1838 2.23386 39.5 14.7812 39.5 14.7812C39.5 14.7812 35.8211 2.23386 21.1648 2.23386C6.50849 2.23386 2 15.1414 2 21.3831C2 24.1807 2.60244 27.3138 4.21721 30.7233M57.8351 10.6218C61.5831 10.6218 64.23 11.8356 66.1558 14.4507C68.0916 17.0707 68.6051 20.1298 68.6051 21.3831C68.6051 23.3271 68.1064 25.2859 67.0743 27.378C67.0743 27.378 66.8817 27.8418 66.541 28.4881M16.4734 46.1867C25.2484 55.4776 34.8975 64.9659 39.5047 69.2339C44.107 64.9659 53.766 55.4678 62.5361 46.1867"
								stroke="#C2A01E"
								stroke-width="3"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>

						<p className="font-kiona text-2xl font-bold text-[#13334C] max-sm:text-base">
							HEALTH
						</p>
					</div>
					<div className="flex flex-col items-center gap-5">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="80"
							height="80"
							viewBox="0 0 81 81"
							fill="none"
						>
							<path
								d="M37.7553 24.1678C38.2457 25.1559 39.3608 26.3416 41.9147 26.3416C46.287 26.3416 46.3564 23.892 46.3564 23.3129C46.3564 22.7339 46.4165 20.197 41.9147 19.6225C37.4129 19.0434 37.7646 16.8007 37.7646 16.3227C37.7646 15.8448 37.8941 13.2481 41.9147 13.2481C44.2558 13.2481 45.2692 14.1949 45.7087 14.9899M41.9148 26.3462V28.7452M41.9148 10.8813V13.2527M29.7046 68.9676L53.2453 67.0787C56.6321 66.7019 57.3077 66.2239 59.5933 64.0271L76.3374 46.177C77.2674 45.1889 77.2118 43.6356 76.2124 42.7164C73.8991 40.5748 70.318 40.5518 67.9769 42.6659L60.5324 49.3941L59.9033 49.9594M37.3761 57.9009L53.454 56.7152C55.1844 56.5865 56.503 55.125 56.4428 53.4016C56.3503 50.7774 54.1525 48.7093 51.5107 48.7599L39.7635 48.9759C39.7635 48.9759 35.5809 44.0032 27.0029 44.6788C23.5144 44.9545 21.0807 45.9426 18.9432 47.496C18.5731 47.7672 18.2121 48.0521 17.8559 48.3554M58.4412 19.6271C58.4412 28.6808 51.0419 36.0203 41.9145 36.0203C32.7871 36.0203 25.3879 28.6808 25.3879 19.6271C25.3879 10.5734 32.7871 3.23389 41.9145 3.23389C51.0419 3.23389 58.4412 10.5734 58.4412 19.6271ZM19.5122 76.1003L5.54416 58.411C4.67434 57.3126 4.86863 55.7225 5.97442 54.8585L13.4142 49.054L30.8153 71.0495L23.704 76.6012C22.4086 77.6123 20.5301 77.3871 19.5122 76.1003ZM22.7973 70.0752C22.7973 70.3417 22.5798 70.5577 22.3115 70.5577C22.0431 70.5577 21.8256 70.3417 21.8256 70.0752C21.8256 69.8086 22.0431 69.5926 22.3115 69.5926C22.5798 69.5926 22.7973 69.8086 22.7973 70.0752Z"
								stroke="#C2A01E"
								stroke-width="3"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<p className="font-kiona text-2xl font-bold text-[#13334C] max-sm:text-base">
							COVERAGE
						</p>
					</div>
					<div className="flex flex-col items-center gap-5">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="80"
							height="80"
							viewBox="0 0 81 81"
							fill="none"
						>
							<g clip-path="url(#clip0_583_2413)">
								<path
									d="M24.0988 76.2339V59.2449M33.0881 76.2198V52.9041L25.5278 44.5861C24.3072 43.2398 24.2977 41.1968 25.5136 39.8458C26.8099 38.4053 29.0572 38.3394 30.4339 39.7093L32.6954 41.9594C37.171 46.4126 44.4286 46.4126 48.8995 41.9594L51.232 39.6386C52.5804 38.297 54.7802 38.3253 56.0907 39.7093C57.3682 41.0556 57.3919 43.1504 56.1428 44.5202L48.5257 52.8994V76.2151M57.5053 76.2339V54.9282M40.8046 76.2339V65.5811M39.0115 23.0686L38.1314 22.1931C36.6411 20.7102 36.6411 18.3001 38.1314 16.8172C39.6217 15.3344 42.0441 15.3344 43.5344 16.8172L48.3696 21.6282C53.3893 26.6227 61.5268 26.6227 66.5465 21.6282L72.4794 15.7251C73.9697 14.2423 76.392 14.2423 77.8823 15.7251C79.3726 17.2079 79.3726 19.6181 77.8823 21.101L66.4992 32.4269V76.2292M15.1054 76.2339V58.5576L7.06249 57.3055L15.1054 32.4269L3.86425 20.1689C2.69093 18.8885 2.71464 16.9208 3.92108 15.6686C5.19375 14.3458 7.30853 14.3129 8.62378 15.5933L15.058 21.8447C19.9264 26.5756 27.6192 26.6839 32.62 22.2119M59.25 4.41923C62.9628 5.29115 65.2623 8.99268 64.386 12.6869C63.5097 16.3811 59.7894 18.6691 56.0766 17.7972C52.3637 16.9253 50.0643 13.2237 50.9406 9.52942C51.8169 5.83518 55.5371 3.54731 59.25 4.41923ZM28.8906 6.26824C31.5882 8.95223 31.5882 13.3038 28.8907 15.9878C26.1932 18.6718 21.8196 18.6718 19.1221 15.9878C16.4246 13.3038 16.4246 8.95219 19.1221 6.2682C21.8196 3.58421 26.1931 3.58425 28.8906 6.26824ZM45.7949 31.7835C46.9368 34.5265 45.6277 37.6712 42.8709 38.8073C40.1141 39.9435 36.9535 38.641 35.8116 35.898C34.6696 33.155 35.9789 30.0103 38.7357 28.8741C41.4925 27.7379 44.653 29.0405 45.7949 31.7835Z"
									stroke="#C2A01E"
									stroke-width="3"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</g>
							<defs>
								<clipPath id="clip0_583_2413">
									<rect
										width="80"
										height="80"
										fill="white"
										transform="translate(0.5 0.233887)"
									/>
								</clipPath>
							</defs>
						</svg>
						<p className="font-kiona text-2xl font-bold text-[#13334C] max-sm:text-base">
							WELLNESS
						</p>
					</div>
				</div>
				<p className="m-0 font-lato text-lg font-bold">
					Dream Finders Homes proudly offers robust benefits for our
					valued employees, including:
				</p>
				<ul className="list-disc pl-10 font-lato text-lg">
					<li>Medical (with FSA and HSA options)</li>
					<li>Dental</li>
					<li>Vision</li>
					<li>Short- and Long-Term Disability</li>
					<li>401k</li>
					<li>Life Insurance</li>
					<li>Cigna Wellness Program</li>
					<li>Nationwide Pet Insurance</li>
					<li>Employee Assistance Program</li>
				</ul>
			</div>
		</section>
	);
};

export default Benefits;
