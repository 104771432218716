import React, { useState, useEffect } from 'react';

interface VideoPopupProps {
	person: {
		img: string;
		name: string;
		video: string;
	};
	classList?: string;
}

const VideoPopup: React.FC<VideoPopupProps> = ({ person, classList }) => {
	const [isOpen, setIsOpen] = useState(false);

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	// Add event listeners to close modal on 'Esc' key press or click outside the video
	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				closeModal();
			}
		};

		const handleOutsideClick = (event: MouseEvent) => {
			const modal = document.querySelector('.modal-container');
			if (modal && !modal.contains(event.target as Node)) {
				closeModal();
			}
		};

		if (isOpen) {
			document.addEventListener('keydown', handleKeyPress);
			document.addEventListener('mousedown', handleOutsideClick);
		}

		return () => {
			document.removeEventListener('keydown', handleKeyPress);
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [isOpen]);

	return (
		<div
			className={
				'rounded- flex h-full max-w-[360px] flex-col items-center ' +
				classList
			}
		>
			<img
				src={person.img}
				alt={person.name + 'Video thumbnail'}
				className="w-full cursor-pointer"
				onClick={openModal}
			/>

			{isOpen && (
				<div
					className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50"
					onClick={(e) => {
						if (e.target === e.currentTarget) {
							closeModal();
						}
					}}
				>
					<div className="relative max-w-screen-md max-md:max-w-[90%]">
						<div
							className="absolute -top-8 right-0 z-10 cursor-pointer"
							onClick={closeModal}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="white"
								className="h-6 w-6 text-white"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</div>
						<iframe
							className={'aspect-video w-[80vw] max-w-[750px]'}
							src={person.video + '?autoplay=1'}
							title={'YouTube video player'}
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowFullScreen
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default VideoPopup;
