import * as React from 'react';

import tony from '../../images/tony-thumbnail.png';
import travis from '../../images/travis-thumbnail.png';
import VideoPopup from '../VideoPopUp/VideoPopUp';

const OurCulture: React.FC = () => {
	return (
		<>
			<section className={'bg-[#13334D] px-5'}>
				<div
					className={
						'container mx-auto flex flex-col gap-4 pb-12 pt-12'
					}
				>
					<h2 className="py-4 text-center font-better-land text-[54px] font-normal text-white max-sm:py-4 max-sm:text-5xl">
						Our Culture
					</h2>
					<div className="mx-auto flex w-full max-w-[740px] flex-col gap-4">
						<p className="m-0 font-lato text-lg  tracking-tight text-white">
							We empower employees by:
						</p>
						<ul className="list-disc pl-10">
							<li className="m-0 font-lato text-lg  tracking-tight text-white">
								Giving them the ability to earn above-market
								compensation by producing above-market results
							</li>
							<li className="m-0 font-lato text-lg  tracking-tight text-white">
								Supporting meritocracy and the right incentive
								structures to drive outperformance
							</li>
							<li className="m-0 font-lato text-lg  tracking-tight text-white">
								Allowing the best and most talented individuals
								to move up, regardless of tenure, rank,
								education or background
							</li>
							<li className="m-0 font-lato text-lg  tracking-tight text-white">
								Encouraging enhanced personal accountability and
								an owner-oriented attitude
							</li>
							<li className="m-0 font-lato text-lg  tracking-tight text-white">
								Maintaining a strong focus on alignment of
								interests among employees, the company and
								shareholders
							</li>
						</ul>
						<p className="m-0 font-lato text-lg  tracking-tight text-white">
							Employees join us in our goal to be a top 5
							homebuilder by:
						</p>
						<ul className="list-disc pl-10">
							<li className="m-0 font-lato text-lg  tracking-tight text-white">
								Running the organization more autonomously so
								decision makers can actually make decisions
							</li>
							<li className="m-0 font-lato text-lg  tracking-tight text-white">
								Maintaining an entrepreneurial spirit and
								allowing individuals to flourish
							</li>
							<li className="m-0 font-lato text-lg  tracking-tight text-white">
								Not becoming encumbered by bureaucratic
								processes
							</li>
							<li className="m-0 font-lato text-lg  tracking-tight text-white">
								Acknowledging and learning from mistakes
							</li>
							<li className="m-0 font-lato text-lg  tracking-tight text-white">
								Ensuring exceptional customer satisfaction
							</li>
						</ul>
						<div className="mx-auto mb-6 mt-4 flex w-full flex-wrap justify-center gap-4 text-center">
							<VideoPopup
								person={{
									img: tony,
									name: 'Tony',
									video: 'https://www.youtube.com/embed/FvwQJ_nEmE0'
								}}
							/>
							<VideoPopup
								person={{
									img: travis,
									name: 'Travis',
									video: 'https://www.youtube.com/embed/drlhsfOOuS4'
								}}
							/>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default OurCulture;
