import React from 'react';
import Layout from '../../layouts/Layout';
import Map from '../../components/Map/Map';
import { PageProps, graphql, useStaticQuery } from 'gatsby';
import HeroImage from '../../components/HeroImage/HeroImage';
import heroImg from '../../images/DFH-benefits-hero.png';
import AboutUs from '../../components/AboutUs/AboutUs';
import Sponsorships from '../../components/Sponsorships/Sponsorships';
import Opportunities from '../../components/Opportunities/Opportunities';
import OurFamily from '../../components/OurFamlity/OurFamily';
import OurMission from '../../components/OurMission/OurMission';
import OurHistory from '../../components/OurHistory/OurHistory';
import OurCulture from '../../components/OurCulture/OurCulture';
import Benefits from '../../components/Benefits/Benefits';
import CareerAdvancement from '../../components/CareerAdvancement/CareerAdvancement';

const IndexPage = (props: PageProps) => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	return (
		<Layout
			title={'Dream Finders Homes: Careers'}
			desc={'Dream Finders Homes: Careers'}
			location={props.location}
		>
			<section
				className={
					'flex min-h-[472px] flex-col items-center justify-center gap-6 bg-primary bg-cover bg-top px-5 md:min-h-[472px]'
				}
				style={{ backgroundImage: `url('${heroImg}')` }}
			>
				<div className="container mx-auto flex flex-col items-center justify-center gap-6">
					<h1 className="text-center font-kiona text-4xl font-normal leading-snug text-white">
						<span className="whitespace-nowrap">BENEFITS &</span>
						<br />
						<span className="font-better-land text-9xl">
							Culture
						</span>
					</h1>
				</div>
			</section>
			<OurHistory />
			<OurCulture />
			<Benefits /> 
			<CareerAdvancement />
		</Layout>
	);
};

export default IndexPage;
